const getEvent = pathname => {
  switch (pathname) {
    case "/kontakt-potwierdzenie/":
      return "Conversion"
    default:
      return "ViewContent"
  }
}

const getContentName = pathname => {
  switch (pathname) {
    case "/":
      return "LandingPage"
    case "/kontakt-potwierdzenie/":
      return "Lead"
    default:
      return "View"
  }
}

export const onRouteUpdate = ({ location }) => {
  const pathname = location.pathname
  const wpAds = document.querySelector("#wp_ads")

  if (wpAds) {
    wpAds.remove()
  }

  const script = document.createElement("script")
  script.id = "wp_ads"
  script.type = "text/javascript"
  script.innerHTML = `wph('track', '${getEvent(
    pathname
  )}', { 'content_name': '${getContentName(pathname)}' })`

  document.body.insertBefore(script, document.body.firstChild)
}
